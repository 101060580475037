// @ts-check

/**
 * Executes a function if app is already open in another tab.
 *
 * @param {Function} handleDuplicateFn Function to execute on duplicate tab
 * @param {string} channel Channel name to use for communication
 */
export function checkDuplicateTab(handleDuplicateFn, channel = "app") {
  const bc = new BroadcastChannel(channel);

  bc.addEventListener("message", (event) => {
    if (event.data === "pong") {
      handleDuplicateFn();
      bc.close();
    } else {
      bc.postMessage("pong");
    }
  });

  bc.postMessage("ping");
}
