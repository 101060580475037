import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const element = this.element;
    $(element).modal("show");
    $(element).on("hidden.bs.modal", function () {
      element.parentElement.removeAttribute("src");
      element.remove();
    });
  }

  change() {
    $(this.element).modal("hide");
  }
}
