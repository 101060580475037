$(document).on("turbo:load turbo:submit-end", () => {
  let surveyPeriodicHandoutScript, updateDurationAndInactive;

  updateDurationAndInactive = function (resp) {
    let button,
      days,
      daysSelector,
      duration,
      inactive,
      inactiveSelector,
      inactiveToggleSelector,
      months,
      monthsSelector,
      periodNum,
      tr,
      weeks,
      weeksSelector,
      years,
      yearsSelector;
    periodNum = $(resp).attr("id").match(/\d+/)[0];
    duration = [];
    inactiveSelector =
      "input[id*=periods_attributes_" + periodNum + "_inactive";
    inactiveToggleSelector = "#toggle_inactive_period_" + periodNum;
    daysSelector = "select[id*=periods_attributes_" + periodNum + "_days]";
    weeksSelector = "select[id*=periods_attributes_" + periodNum + "_weeks]";
    monthsSelector = "select[id*=periods_attributes_" + periodNum + "_months]";
    yearsSelector = "select[id*=periods_attributes_" + periodNum + "_years]";
    inactive = $(inactiveSelector).val() === "1";
    $("select[id*=periods_attributes_" + periodNum + "_").prop(
      "disabled",
      inactive
    );
    button = $(inactiveToggleSelector);
    tr = $(button).closest("tr");
    if (inactive) {
      tr.css("background-color", "#DDD");
      button.text("Genaktiver");
    } else {
      tr.css("background-color", "white");
      button.text("Inaktiver");
    }
    if ($(daysSelector).val() > 0) {
      days =
        $(daysSelector).val() +
        " " +
        ($(daysSelector).val() === "1" ? "dag" : "dage");
      duration.push(days);
    }
    if ($(weeksSelector).val() > 0) {
      weeks =
        $(weeksSelector).val() +
        " " +
        ($(weeksSelector).val() === "1" ? "uge" : "uger");
      duration.push(weeks);
    }
    if ($(monthsSelector).val() > 0) {
      months =
        $(monthsSelector).val() +
        " " +
        ($(monthsSelector).val() === "1" ? "måned" : "måneder");
      duration.push(months);
    }
    if ($(yearsSelector).val() > 0) {
      years = $(yearsSelector).val() + " " + "år";
      duration.push(years);
    }
    return $("#period" + periodNum + " .duration").text(
      duration.length > 0 ? duration.join(" ") : "Ugyldig"
    );
  };

  surveyPeriodicHandoutScript = function () {
    $("#survey_periodic_handout_from").on(
      "click",
      "a#add_period",
      function (e) {
        let a, ids, nextNumber, panel, table;
        a = $(e.target).closest("a");
        panel = a.closest(".panel");
        table = panel.find("table.periods");
        ids = $.map($("tr"), function (val) {
          return $(val).data("id");
        });
        nextNumber = Math.max.apply(Math, ids) + 1;
        $.get(
          "/survey/periodic_handouts/new_period",
          {
            nextNumber: nextNumber,
          },
          function (result) {
            return table.find("tbody").append(result);
          }
        );
        return e.preventDefault();
      }
    );
    $("#survey_periodic_handout_from").on(
      "click",
      'a[role="remove_period"]',
      function (e) {
        let tr;
        e.preventDefault();
        tr = $(this).closest("tr");
        $(tr).find("._destroy").val("1");
        return tr.hide();
      }
    );
    $("#survey_periodic_handout_from").on(
      "click",
      'a[role="toggle_inactive_period"]',
      function (e) {
        let inactive, tr;
        e.preventDefault();
        tr = $(this).closest("tr");
        inactive = $(tr).find("._inactive").val();
        if (inactive === "1") {
          $(tr).find("._inactive").val("0");
        } else {
          $(tr).find("._inactive").val("1");
        }
        return updateDurationAndInactive(this);
      }
    );
    $("#survey_periodic_handout_from").on(
      "change",
      "select[id*=periods_attributes]",
      function () {
        return updateDurationAndInactive(this);
      }
    );
    return $("select[id*=periods_attributes]").each(function (i, e) {
      return updateDurationAndInactive(e);
    });
  };

  $(surveyPeriodicHandoutScript);
});
