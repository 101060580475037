/* eslint-disable no-undef */
import "jquery-sortable";

$(document).on("turbo:load", function () {
  $(".sortable").sortable({
    handle: ".draggable",
    isValidTarget: function (item, container) {
      if (item.is(".parent") && !$(container.el[0]).is(".parent")) {
        // prevent dragging a parent into a parent in app/views/survey/display_profiles/sort_items.html.erb
        return false;
      }
      return true;
    },
  });
});
