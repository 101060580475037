$(document).on("turbo:load", function () {
  $("#patient_cpr").elastic();
  window.searchPatient = function (query, callback) {
    return $.ajax({
      url: "/patients/search/?q=" + query,
      dataType: "json",
      async: true,
      success: function (data) {
        return callback(data);
      },
    });
  };

  $("#patient_cpr").typeahead({
    source: window.searchPatient,
    matcher: function () {
      return true;
    },
    afterSelect: function () {
      $("#search_form").submit();
    },
  });
});
