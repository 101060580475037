import "@hotwired/turbo-rails";
import "iframe-resizer";
import iframeResize from "iframe-resizer/js/iframeResizer";
import flatpickr from "flatpickr";

import "@/tom-select.js";
import "@/jquery_elastic_source.js";
import "@/tinymce-setup.js";
import "@/bootstrap3-typeahead.js";
import "@/sortable.js";
import "@/survey_periodic_handout.js";
import "@/home.js";
import "@/bootstrap.js";
import "@/init-stimulus.js";

import { checkDuplicateTab } from "@/duplicate-tab.js";

$(document).on("turbo:load", () => {
  flatpickr(".flatpickr");
  $("[data-toggle='tooltip']").tooltip();
  $("[data-toggle='popover']").popover();
  iframeResize({ log: false }, "#iframe");
});


checkDuplicateTab(() => {
  document.open();
  document.write(`
    <div style="padding: 1rem; text-align: center">
      <h1>Kun muligt at arbejde i en fane</h1>
      <h3>Du har en anden fane åben med dette system.</h3>
    </div>
  `);
  document.close();
});